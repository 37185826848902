import { memo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { routes } from "constants/route";
import styledSystem from "helpers/styledSystem";
import useStorage from "services/hooks/useStorage";
import { hengsrodLogo3x } from "constants/asset";

const HLogo = styledSystem(styled.img`
  cursor: pointer;
`);

export const HengsrodLogoSmall = memo((props) => {
  const history = useHistory();
  const [token] = useStorage("token");
  let directUrl = routes.URL_LOGIN;
  if (token) {
    directUrl = routes.URL_BUILDS || routes.URL_PROJECTS;
  }
  return (
    <HLogo
      {...props}
      src={hengsrodLogo3x}
      title="Hengsrod Logo"
      alt="Hengsrod Logo"
      onClick={() => history.push(directUrl)}
    />
  );
});
