import React, { Suspense, lazy } from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import styled from "styled-components";

import { routes, publicRouteList } from "constants/route";
import styledSystem from "helpers/styledSystem";

import PublicRoute from "./PublicRoute";
import SuspenseLoader from "components/atoms/SuspenseLoader";
import BackdropLoader from "components/atoms/BackdropLoader";
import PrivateView from "views/private";

import { useCommonContextDeps } from "services/contexts/CommonContext";

const LogIn = lazy(() => import("views/public/LogIn"));
const Invalid = lazy(() => import("views/public/Invalid"));
const PasswordForgot = lazy(() => import("views/public/PasswordForgot"));
const PasswordUpdate = lazy(() => import("views/public/PasswordUpdate"));
const ValidateLink = lazy(() => import("views/public/ValidateLink"));

const HWrap = styledSystem(styled.div``);

const publicRouteMap = {
  login: <LogIn />,
  "password-forgot": <PasswordForgot />,
  "password-update": <PasswordUpdate />,
  invalid: <Invalid />,
  "validate-link": <ValidateLink />,
};

function RouteSwitch() {
  const { privateRoutesByPerms } = useCommonContextDeps();
  const location = useLocation();
  // console.log("RouteSwitch");
  return (
    <Switch>
      {publicRouteList.map(({ routeId, pageKey }) => {
        return (
          <PublicRoute
            key={pageKey}
            path={routeId}
            component={publicRouteMap[pageKey]}
            exact
            strict
          />
        );
      })}
      {privateRoutesByPerms &&
        privateRoutesByPerms.length &&
        privateRoutesByPerms.map(({ pageKey }) => {
          return (
            <Route
              key={pageKey}
              path="/:pageId"
              component={PrivateView}
              exact
              strict
            />
          );
        })}
      <Redirect
        from="/"
        to={{
          pathname: routes.URL_LOGIN,
          state: { from: location },
        }}
        exact
        strict
      />
      <Route>{publicRouteMap["invalid"]}</Route>
    </Switch>
  );
}

function Routes() {
  return (
    <>
      <Suspense fallback={SuspenseLoader}>
        <BrowserRouter>
          <HWrap bg="bg-grey">
            <RouteSwitch />
          </HWrap>
        </BrowserRouter>
      </Suspense>
      <BackdropLoader />
    </>
  );
}

export default Routes;
