import React from "react";
import styled from "styled-components";

import styledSystem from "helpers/styledSystem";
import { BaseWrap } from "components/base";

import UserInfo from "components/atoms/UserInfo";
import HSideMenuItems from "components/molecules/HSideMenuItems";
import { HNeedHelp } from "components/atoms/HNeedHelp";
import { HLogoWrapper } from "./HLogoWrapper";

const HWrap = styledSystem(styled(BaseWrap)``);
const HMenu = styledSystem(styled(BaseWrap)``);
const HMenuSection = styledSystem(styled(BaseWrap)`
  &::-webkit-scrollbar {
    display: none;
  }
`);

export default function HSideMenu({ norwegianFieldMap }) {
  const translationProps = {
    norwegianFieldMap,
  };
  return (
    <HMenu bg="bg-grey">
      <HWrap>
        <HMenuSection
          display="grid"
          gridTemplateColumns="100%"
          gridTemplateRows="auto auto 1fr auto auto auto"
          alignItems="flex-start"
          overflow="auto"
          height="100vh"
        >
          <UserInfo {...translationProps} />
          <HSideMenuItems />
          <span></span>
          <HNeedHelp {...translationProps} />
          <HLogoWrapper {...translationProps} />
        </HMenuSection>
      </HWrap>
    </HMenu>
  );
}
