import React from "react";
import styled from "styled-components";
import MuiSkeleton from "@mui/material/Skeleton";

import styledSystem from "helpers/styledSystem";

import { BaseText, BaseWrap } from "components/base";
import { PhoneIcon } from "constants/icon";

const HNeedHelpSection = styledSystem(styled(BaseWrap)``);
const HText = styledSystem(styled(BaseText)``);
const HPhoneIcon = styledSystem(styled(BaseWrap)`
  cursor: pointer;
`);

export const HNeedHelp = ({ norwegianFieldMap }) => {
  return (
    <HNeedHelpSection
      display={{ _: "grid" }}
      bg={{ _: "bg-grey" }}
      gridTemplateColumns={{
        _: "1fr 50px",
      }}
      gridTemplateRows={{
        _: "25px 25px",
      }}
      justifyContent={{ _: "center" }}
      alignItems={{ _: "center" }}
      p={"20px 40px 10px 40px"}
    >
      <HPhoneIcon
        gridRow={{ _: "1/3" }}
        justifyContent={{ _: "center" }}
        justifySelf={{ _: "center" }}
        alignItems={{ _: "center" }}
      >
        <a href="tel:+33061120">{PhoneIcon}</a>
      </HPhoneIcon>
      <HText
        gridRow={{ _: "1/2" }}
        gridColumn={{ _: "1/2" }}
        fontFamily={{ _: "proxima-nova" }}
        fontSize={{ _: 15 }}
        fontWeight={{ _: 400 }}
        color="text-grey"
      >
        {norwegianFieldMap["do-you-need-help"] ?? <MuiSkeleton width={60} />}
      </HText>
      <HText
        gridRow={{ _: "2/3" }}
        gridColumn={{ _: "1/2" }}
        fontFamily={{ _: "proxima-nova" }}
        fontSize={{ _: 15 }}
        fontWeight={{ _: 400 }}
        color="text-grey"
      >
        {norwegianFieldMap["reach-out-to-us-at"]} 33 06 11 20
      </HText>
    </HNeedHelpSection>
  );
};
