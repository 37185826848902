import styled from "styled-components";
import styledSystem from "helpers/styledSystem";
import { Divider } from "antd";

import { BaseWrap } from "components/base";
import { HengsrodLogo } from "components/atoms/HengsrodLogo";

const HWrap = styledSystem(styled(BaseWrap)``);
const HDivider = styledSystem(styled(Divider)``);
const dividerStyle = {
  margin: "10px 0px",
};

export const HLogoWrapper = ({ handleDrawerCloseButtonClick = () => null }) => {
  return (
    <HWrap
      textAlign={{ _: "center" }}
      mx={3}
      pb={3}
      onClick={handleDrawerCloseButtonClick}
    >
      <HDivider
        display={{
          xs: "block",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        }}
        style={dividerStyle}
      />
      <HengsrodLogo width="167.18" height="67" />
    </HWrap>
  );
};
