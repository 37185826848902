import {
  format,
  getDate,
  getMonth,
  getYear,
  isToday,
  subMonths,
  isBefore,
} from "date-fns";

const norwegianLocaleStr = "nb-NO";
export const longDate = {
  dateStyle: "long",
};
export const mediumDate = {
  dateStyle: "medium",
};
export const longDateShortTime = {
  dateStyle: "long",
  timeStyle: "short",
};
export const hourMinute = {
  hour: "numeric",
  minute: "numeric",
};

export function getNorwayDate(date) {
  if (!date) return "";
  const replacedDate = new Date(date.replace(/-/g, "/"));
  const formattedDate = new Intl.DateTimeFormat(
    norwegianLocaleStr,
    longDate
  ).format(replacedDate);
  return formattedDate;
}

export function getNorwayDateTime(date, options) {
  if (!date) return "";
  const replacedDate = new Date(date.replace(/-/g, "/"));
  const formattedDate = new Intl.DateTimeFormat(
    norwegianLocaleStr,
    options
  ).format(replacedDate);
  return formattedDate;
}

export function getAPIDateFormat(date, dateFormat) {
  if (!date) return null;
  const _date = new Date(date);
  const formattedDate = `${format(_date, dateFormat)}`;
  return formattedDate;
}

export function getDateInText(date, options) {
  if (!date) return "";
  return isToday(date) ? "Today" : getNorwayDateTime(date, options);
}

export function getFormattedDate(date) {
  const year = getYear(new Date(date));
  const month = getMonth(new Date(date));
  const day = getDate(new Date(date));
  const formattedDate = new Date(year, month, day);
  return formattedDate;
}

export function getDateNMonthBefore(date, targetMonths = 1) {
  if (!date) return null;

  const targetDateNMonthBefore = subMonths(new Date(date), targetMonths);
  return targetDateNMonthBefore;
}

export function getIsDateBeforePresentDate(date) {
  if (!date) return null;

  const presentDate = new Date();
  return isBefore(new Date(date), presentDate);
}
