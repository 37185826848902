import React, { memo } from "react";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import MuiSkeleton from "@mui/material/Skeleton";

import styledSystem from "helpers/styledSystem";
import { BaseText, BaseWrap } from "components/base";

import {
  DriversIcon,
  ModuleIcon,
  NewsIcon,
  SettingsIcon,
  SignOutIcon,
} from "constants/icon";
import { logOut } from "helpers/logout";
import { useCommonContextDeps } from "services/contexts/CommonContext";
import BadgeMenuServiceStatus from "components/atoms/BadgeMenuServiceStatus";

const HWrap = styledSystem(styled(BaseWrap)``);
const HIconWrap = styledSystem(styled(BaseWrap)`
  cursor: pointer;
`);
const HSignOut = styledSystem(styled(BaseWrap)``);
const HText = styledSystem(styled(BaseText)`
  text-transform: uppercase;
  cursor: pointer;
`);
const HNavLink = styledSystem(styled(NavLink)`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`);
const HMenuTextWrap = styledSystem(styled(BaseWrap)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
`);

const marginValues = {
  builds: {
    iconSpace: "12px",
    labelSpace: "17px",
  },
  projects: {
    iconSpace: "12px",
    labelSpace: "17px",
  },
  drivers: {
    iconSpace: "12px",
    labelSpace: "13px",
  },
  news: {
    iconSpace: "10px",
    labelSpace: "13px",
  },
  settings: {
    iconSpace: "10px",
    labelSpace: "13px",
  },
  signout: {
    iconSpace: "14px",
    labelSpace: "15px",
  },
};

const iconMap = {
  projects: ModuleIcon,
  builds: ModuleIcon,
  news: NewsIcon,
  drivers: DriversIcon,
  settings: SettingsIcon,
  signout: SignOutIcon,
};

const HSideMenuItems = memo(({ handleDrawerCloseButtonClick }) => {
  const { privateRoutesByPerms, appState } = useCommonContextDeps();
  const { builds, projects, norwegianFieldMap } = appState;
  const norwegianMap = {
    projects: norwegianFieldMap["projects"],
    builds: norwegianFieldMap["builds"],
    news: norwegianFieldMap["news"],
    drivers: norwegianFieldMap["drivers"],
    settings: norwegianFieldMap["settings"],
  };
  const handleLogOut = () => logOut();
  const SignOutIconComponent = iconMap["signout"];
  const location = useLocation();
  const { pathname } = location;

  const isPendingActionBuilds = builds.some(
    (bObj) =>
      bObj &&
      bObj.services &&
      bObj.services.length &&
      bObj.services.some((sObj) => sObj.statusKey === 1)
  );

  const anyBuildBeforeNMonthToRecommendedDate = builds.some(
    (bObj) => !!(bObj && bObj.isNMonthBeforeRecommendedDate)
  );

  const anyBuildMessagesUnread = builds.some(
    (bObj) =>
      bObj &&
      bObj.messages &&
      bObj.messages.length &&
      bObj.messages.some((mObj) => mObj.statusKey === 0)
  );

  const anyProjectMessagesUnread = projects.some(
    (pObj) =>
      pObj &&
      pObj.messages &&
      pObj.messages.length &&
      pObj.messages.some((mObj) => mObj.statusKey === 0)
  );

  let projectBadgeStatusKey = null;
  let buildBadgeStatusKey = null;

  if (anyBuildMessagesUnread) {
    buildBadgeStatusKey = 0;
  } else if (isPendingActionBuilds) {
    buildBadgeStatusKey = 1;
    if (!anyBuildBeforeNMonthToRecommendedDate) buildBadgeStatusKey = null;
  }

  if (anyProjectMessagesUnread) {
    projectBadgeStatusKey = 0;
  }

  const badgeMap = {
    projects: {
      badgeStatusKey: projectBadgeStatusKey,
    },
    builds: {
      badgeStatusKey: buildBadgeStatusKey,
    },
  };

  // console.log({
  //   isPendingActionBuilds,
  //   anyBuildMessagesUnread,
  //   anyProjectMessagesUnread,
  // });

  return (
    <HWrap display={{ _: "grid" }} alignItems={{ _: "flex-start" }} p="30px">
      {privateRoutesByPerms.map(({ pageKey, routeId, label, isBadgeShown }) => {
        const IconComponent = iconMap[pageKey];
        return (
          <HWrap
            key={pageKey}
            display={{ _: "flex" }}
            justifyContent={{ _: "flex-start" }}
            alignItems={{ _: "center" }}
            py={2}
            my={1}
          >
            <HMenuTextWrap>
              <HNavLink to={routeId} onClick={handleDrawerCloseButtonClick}>
                <HIconWrap
                  as="span"
                  ml={marginValues[pageKey].iconSpace}
                  display={{ _: "flex" }}
                  justifyContent={{ _: "flex-start" }}
                  alignItems={{ _: "center" }}
                >
                  {
                    <IconComponent
                      color={pathname === routeId ? "hengsrod-green" : ""}
                    />
                  }
                </HIconWrap>
                <HText
                  ml={marginValues[pageKey].labelSpace}
                  color={pathname === routeId ? "hengsrod-green" : "text-grey"}
                  fontWeight={{ _: 500 }}
                  fontSize={{ _: 14 }}
                  fontFamily={{ _: "rubik" }}
                >
                  {norwegianMap[pageKey] ?? <MuiSkeleton width={100} />}
                </HText>
              </HNavLink>
              {isBadgeShown ? (
                <BadgeMenuServiceStatus
                  {...badgeMap[pageKey]}
                  norwegianFieldMap={norwegianFieldMap}
                />
              ) : (
                <span></span>
              )}
            </HMenuTextWrap>
          </HWrap>
        );
      })}
      <HSignOut
        onClick={handleLogOut}
        display={{ _: "flex" }}
        justifyContent={{ _: "flex-start" }}
        alignItems={{ _: "center" }}
        py={2}
        my={1}
      >
        <HIconWrap
          as="span"
          ml={marginValues["signout"].iconSpace}
          display={{ _: "flex" }}
          justifyContent={{ _: "flex-start" }}
          alignItems={{ _: "center" }}
        >
          {<SignOutIconComponent />}
        </HIconWrap>
        <HText
          ml={marginValues["signout"].labelSpace}
          color={"text-grey"}
          fontWeight={{ _: 500 }}
          fontSize={{ _: 14 }}
          fontFamily={{ _: "rubik" }}
        >
          {norwegianFieldMap["sign-out"] ?? <MuiSkeleton width={100} />}
        </HText>
      </HSignOut>
    </HWrap>
  );
});

export default HSideMenuItems;
