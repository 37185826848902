import { useEffect, useState } from "react";

const useScrollListener = () => {
  const [windowScrollData, setWindowScrollData] = useState({
    y: 0,
    prevY: 0,
  });
  const { y: windowY, prevY: windowPrevY } = windowScrollData;
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      setWindowScrollData((prev) => ({
        y: window.scrollY,
        prevY: prev.y,
      }));
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const isMenuVisible = !(windowY > 20 && windowY - windowPrevY > 0);
    setIsMenuVisible(isMenuVisible);
  }, [windowY, windowPrevY]);

  // console.log("useScrollListener", windowScrollData);

  return {
    isMenuVisible,
  };
};

export default useScrollListener;
