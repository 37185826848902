import ApiCore from "./utils/core";
import { API_TRANSLATIONS } from "constants/api";

const resourcePath = API_TRANSLATIONS;

const translationAPI = new ApiCore({
  resourcePath: resourcePath,
  getAll: true,
});

// translationAPI.customMethod = () => {};

export default translationAPI;
