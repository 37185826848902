import React from "react";
import styled from "styled-components";

import styledSystem from "helpers/styledSystem";
import { BaseText, BaseWrap } from "components/base";

const HText = styledSystem(styled(BaseText)``);
const HBadge = styledSystem(styled(BaseWrap)`
  background-color: ${({ theme, statusbgcolor }) =>
    theme.colors[statusbgcolor]};
  text-transform: uppercase;
  border-radius: 14px;
  border: 0;
  width: 143px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`);

const statusBgColorMap = {
  0: "button-grey",
  1: "button-grey",
};

const statusTextColorMap = {
  0: "text-grey",
  1: "text-grey",
};

export default function BadgeMenuServiceStatus({
  badgeStatusKey,
  norwegianFieldMap,
}) {
  const statusTextMap = {
    0: norwegianFieldMap["pending-actions"],
    1: norwegianFieldMap["pending-actions"],
  };
  // console.log("BadgeMenuServiceStatus", badgeStatusKey);
  return (
    badgeStatusKey !== null && (
      <HBadge
        status={badgeStatusKey}
        statusbgcolor={statusBgColorMap[badgeStatusKey]}
        display={{
          _: "flex",
        }}
      >
        <HText
          fontFamily="proxima-nova"
          fontSize={11}
          fontWeight={600}
          lineHeight={"13.4px"}
          color={statusTextColorMap[badgeStatusKey]}
          pl={1}
        >
          {statusTextMap[badgeStatusKey]}
        </HText>
      </HBadge>
    )
  );
}
