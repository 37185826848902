import axios from "axios";

import { API_URL_BASE } from "constants/api";
import { targetStorage } from "services/hooks/useStorage";

const axiosInstance = axios.create({
  baseURL: API_URL_BASE,
});

const getAll = async (resourcePath) =>
  await axiosInstance.get(`${API_URL_BASE}${resourcePath}`);

const getOne = async (resourcePath, email) =>
  await axiosInstance.get(`${API_URL_BASE}${resourcePath}?email=${email}`);

const post = async (resourcePath, body) =>
  await axiosInstance.post(`${API_URL_BASE}${resourcePath}`, body);

const put = async (resourcePath, body) =>
  await axiosInstance.put(`${API_URL_BASE}${resourcePath}`, body);

const _delete = async (resourcePath, body) =>
  await axiosInstance.delete(`${API_URL_BASE}${resourcePath}`, body);

function requestInterceptor(config) {
  // console.log("req", config);
  const accessToken = targetStorage.getItem("token");
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return config;
}

function responseInterceptor(response) {
  // console.log("response", { response });
  if (response && response.data && response.data.res && response.data.status) {
    const statusSuccessMsgText = response.data.res.message || "Success";
    return {
      res: response.data.res,
      statusCode: response.status,
      statusText: response.statusText,
      status: response.data.status,
      statusMsg: statusSuccessMsgText,
    };
  } else {
    const statusErrorMsgText = response.data.res.error_message || "Error";
    // Auth error
    // console.log("response Err", { response });
    return {
      statusCode: response.data.res.error_code,
      statusText: response.statusText,
      status: response.data.status,
      statusMsg: statusErrorMsgText,
    };
  }
}

function rejectInterceptor(error) {
  // console.log("reject", { error });
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.res &&
    !error.response.data.status
  ) {
    const statusMsgText = error.response.data.res.error_message || "Error";
    return {
      statusCode: error.response.status,
      statusText: error.response.statusText,
      status: error.response.data.status,
      statusMsg: statusMsgText,
      // statusDesc: error.response.data.res.error_message,
    };
  } else if (error && error.message) {
    // console.log("reject Err", { error });

    return {
      status: false,
      statusMsg: error.message,
      statusDesc: "Something went wrong. Please try again",
    };
  }
}

axiosInstance.interceptors.request.use(requestInterceptor, rejectInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor, rejectInterceptor);

export const apiProvider = {
  getAll,
  getOne,
  post,
  put,
  _delete,
};
