export const getUserRights = (isOwner, userRights) => {
  const additionalDriverRights =
    userRights && userRights.length ? userRights : [];

  let allowedRightsList = [];

  if (isOwner) {
    // if role:Owner then apply all available rights.
    allowedRightsList = ["request_service", "view_reply_msg", "rate_service"];
  } else {
    // if role:Driver then apply available driver's rights.
    allowedRightsList =
      additionalDriverRights && additionalDriverRights.length
        ? additionalDriverRights.map((rObj) => rObj.rightsId)
        : [];
  }

  const canReqServiceAndAppointment =
    allowedRightsList.includes("request_service");
  const canViewAndReply = allowedRightsList.includes("view_reply_msg");
  const canRateService = allowedRightsList.includes("rate_service");

  return {
    canReqServiceAndAppointment,
    canViewAndReply,
    canRateService,
  };
};

export function getUserMappedFormFieldsId(
  submittedFormData,
  formFieldSet,
  formFieldKeys
) {
  const formFieldIds = {};
  formFieldKeys.forEach((filterKey) => {
    if (formFieldIds[filterKey]) {
      formFieldIds[filterKey] = [];
    }
    let idList = [];

    const submittedData =
      submittedFormData && submittedFormData[filterKey]
        ? submittedFormData[filterKey]
        : null;

    if (Array.isArray(submittedData)) {
      submittedData &&
        submittedData.forEach((optionText) => {
          formFieldSet &&
            formFieldSet[filterKey].forEach((optionObj) => {
              if (
                filterKey !== "additionalDriverRights" &&
                optionObj &&
                optionText &&
                optionObj.title === optionText
              ) {
                idList = [...idList, optionObj.itemId];
              }
              if (
                filterKey === "additionalDriverRights" &&
                optionObj &&
                optionText &&
                optionObj.rightsId === optionText
              ) {
                idList = [...idList, optionObj.itemId];
              }
            });
        });
    } else {
      formFieldSet &&
        formFieldSet[filterKey].forEach((optionObj) => {
          if (optionObj && submittedData && optionObj.title === submittedData)
            idList = [...idList, optionObj.itemId];
        });
    }
    formFieldIds[filterKey] = idList;
  });
  return formFieldIds;
}
