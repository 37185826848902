import socketio from "socket.io-client";

import { createContext, useContext, useEffect, useRef, useState } from "react";
import { API_URL_WS } from "constants/api";

export const SocketContext = createContext();
const { Provider: SocketProvider } = SocketContext;

export const socketNs = {
  connect: "connect",
  connect_error: "connect_error",
  disconnect: "disconnect",
};

export function SocketContextProvider({ children, token, isLoggedIn }) {
  const socketClient = useRef(null);
  const [socketErrorStatus, setSocketErrorStatus] = useState({
    socketErrMsg: "",
    msgThreadErrMsgObj: {},
  });

  useEffect(() => {
    if (isLoggedIn) {
      // console.log("SocketContextProvider", isLoggedIn);
      socketClient.current = socketio.connect(API_URL_WS, {
        auth: { token },
        reconnection: true,
        // reconnectionAttempts: 2,
        // transports: ["websocket", "polling"],
        // forceNew: true,
      });

      const handleConnectionOnSuccess = () => {
        setSocketErrorStatus((prev) => ({
          ...prev,
          msgThreadErrMsgObj: {},
          socketErrMsg: "",
        }));
      };

      const hanldeConnectionOnError = (_) => {
        setSocketErrorStatus((prev) => ({
          ...prev,
          msgThreadErrMsgObj: {},
          // socketErrMsg: "Connection refused. Reconnecting...",
          socketErrMsg: "Tilkobling feilet. Kobler til på nytt...",
        }));
      };

      socketClient.current.on(socketNs.connect, handleConnectionOnSuccess);
      socketClient.current.on(socketNs.connect_error, hanldeConnectionOnError);
      return () =>
        socketClient.current.on(socketNs.disconnect, (reason) => {
          setSocketErrorStatus((prev) => ({
            ...prev,
            msgThreadErrMsgObj: {},
            // socketErrMsg: reason
            //   ? reason.toUpperCase()
            //   : "Connection disconnected.",
            socketErrMsg: reason ? reason.toUpperCase() : "Koblet fra.",
          }));
        });
    }
  }, [token, isLoggedIn]);

  const contextValue = {
    socketClient: socketClient.current,
    ...socketErrorStatus,
    setSocketErrorStatus,
  };

  // console.log("SocketContextProvider", {
  //   socketClient: socketClient.current,
  //   ...socketErrorStatus,
  // });

  return <SocketProvider value={contextValue}>{children}</SocketProvider>;
}

export function useSocketContextDeps() {
  const context = useContext(SocketContext);

  if (!context) {
    // console.log(context);
    throw new Error(`Components cannot be rendered outside the Socket context`);
  }
  return context;
}

export default SocketContextProvider;
