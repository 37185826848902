export function getNormalizedTranslationData(data = []) {
  const resultObject = data.reduce((result, currentObject) => {
    for (let key in currentObject) {
      result[key] = currentObject.hasOwnProperty(key)
        ? currentObject[key]
        : "?";
    }
    return result;
  }, {});
  return resultObject;
}
