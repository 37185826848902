/* Below values are scale values and not css properties and its values */
const theme = {
  breakpoints: {
    xs: "0",
    sm: "600px",
    md: "960px",
    lg: "1280px",
    xl: "1920px",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors: {
    "text-grey": "#666666",
    "text-light-grey": "#222222",
    "text-white": "#FFFFFF",
    "transparent-black": "#00000026",
    "hengsrod-green": "#81BE48",
    "hengsrod-green-lite": "rgba(129, 190, 72, 0.4)",
    black: "#2A2A2E",
    "dark-grey": "#4B4A48",
    "warning-yellow": "#FFF8EA",
    "warning-yellow-text": "#C89920",
    "error-red": "#FFF1F1",
    "error-red-text": "#B83D3D",
    "success-green": "#DBF2EC",
    "success-green-text": "#3B7E6D",
    "bg-grey": "#F5F6F6",
    "client-info-bg-grey": "rgba(209, 209, 209, 0.25)",
    "menu-bg-grey": "rgba(209, 209, 209, 0.3)",
    "button-grey": "rgba(209, 209, 209, 0.3)",
    "sub-text-grey": "#777777",
    "link-blue": "#40a9ff",
    "main-bg-grey": "#E5E5E5",
    "border-black": "rgba(39, 40, 62, 0.1)",
    "pending-red": "#E08B8B",
    brown: "#DB9224",
    "border-grey": "rgba(39, 40, 62, 0.1)",
    "chat-icon-grey": "#D1D1D1",
    "initial-bg-grey": "#F1F1F1",
  },
  // Typography
  fonts: {
    "proxima-nova": "proxima-nova",
    rubik: "rubik",
  },
  fontSizes: {
    small: 14,
    medium: 16,
    large: 18,
    h5: {
      sm: 12,
      md: 14,
      lg: 16,
    },
    h4: {
      sm: 16,
      md: 18,
      lg: 22,
    },
    h3: {
      sm: 19,
      md: 22,
      lg: 28,
    },
    h2: {
      sm: 20,
      md: 22,
      lg: 24,
    },
    h1: {
      sm: 24,
      md: 26,
      lg: 30,
    },
  },
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: {
    solid: 1,
    title: 1.25,
    copy: 1.5,
  },
  letterSpacings: {
    normal: "normal",
    tracked: "0.1em",
    tight: "-0.05em",
    mega: "0.25em",
  },

  // Layout
  width: [16, 32, 64, 128, 256],
  heights: [16, 32, 64, 128, 256],
  minWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  maxWidths: [16, 32, 64, 128, 256, 512, 768, 1024, 1536],
  sizes: [0, 10, 20, 30, 40, 50], // size (width & height)

  // Border
  borders: [
    0,
    "1px solid",
    "2px solid",
    "4px solid",
    "8px solid",
    "16px solid",
    "32px solid",
  ],
  borderWidths: [],
  borderStyles: [],
  radii: [0, 2, 4, 16, 9999, "100%"],

  // Position
  zIndices: [1, 2, 3, 4, 5],

  // Shadow
  shadows: [
    "0px 0px 55px rgba(0, 0, 0, 0.05)",
    "0px 4px 10px rgba(0, 0, 0, 0.04)",
    "12px -5px 25px 0px rgba(0, 0, 0, 0.2)",
  ],
};

export default theme;
