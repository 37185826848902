import { apiProvider } from "./provider";

class ApiCore {
  constructor(options) {
    if (options.getAll) {
      this.getAll = () => {
        return apiProvider.getAll(options.resourcePath);
      };
    }

    if (options.getOne) {
      this.getOne = (email) => {
        return apiProvider.getOne(options.resourcePath, email);
      };
    }

    if (options.post) {
      this.post = (body) => {
        return apiProvider.post(options.resourcePath, body);
      };
    }

    if (options.put) {
      this.put = (body) => {
        return apiProvider.put(options.resourcePath, body);
      };
    }

    if (options._delete) {
      this._delete = (id) => {
        return apiProvider._delete(options.resourcePath, id);
      };
    }
  }
}

export default ApiCore;
