import { transformMessageThread } from "./message";

const sortProjectByStatusKey = (a, b) => {
  const nullCheckVal =
    Number(a.statusKey === null) - Number(b.statusKey === null);
  return (
    nullCheckVal || +(a.statusKey > b.statusKey) || -(a.statusKey < b.statusKey)
  );
};

export const modifyProjects = (pObj, canViewAndReply) => {
  const progressOfProject = pObj && pObj.progress ? pObj.progress.itemId : null;
  const messagesOfProjects =
    pObj && pObj.messages && pObj.messages.length ? pObj.messages : [];
  return {
    ...pObj,
    progressTitleKey: progressOfProject,
    sort_status: progressOfProject,
    sort_deadline_date: pObj && pObj.deadline ? pObj.deadline : null,
    messages: canViewAndReply
      ? messagesOfProjects.map(transformMessageThread)
      : messagesOfProjects,
  };
};

const projectIdsList = {};
export const integrateProjectProps = (pObj) => {
  if (pObj && !projectIdsList[pObj.itemId]) {
    projectIdsList[pObj.itemId] = {};
    projectIdsList[pObj.itemId]["sort_status"] = pObj && pObj.sort_status ? pObj.sort_status : null;
    projectIdsList[pObj.itemId]["unread_msg_thread_total_count"] = 0;
  }

  if (pObj && pObj.messages && pObj.messages.length) {
    const sortedMessages = pObj.messages.sort(sortProjectByStatusKey);
    projectIdsList[pObj.itemId]["sort_status"] = sortedMessages[0].statusKey;
    projectIdsList[pObj.itemId]["unread_msg_thread_total_count"] =
      sortedMessages.reduce(
        (sum, { unreadCommentsCount = 0 }) => sum + unreadCommentsCount,
        0
      );
  }

  return {
    ...pObj,
    sort_status: projectIdsList[pObj.itemId].sort_status,
    unread_msg_thread_total_count:
      projectIdsList[pObj.itemId].unread_msg_thread_total_count,
  };
};
