export const ratingPositiveAndNegative = [2, 3];

export const modifyServicesOfBuild = (sObj) => {
  const serviceObj = { ...sObj };
  const hasRatingGiven = !!(
    serviceObj &&
    serviceObj.rating &&
    serviceObj.rating.length &&
    ratingPositiveAndNegative.includes(serviceObj.rating[0].itemId)
  );
  const isRatingGood = !!(
    serviceObj &&
    serviceObj.rating &&
    serviceObj.rating.length &&
    serviceObj.rating[0] &&
    serviceObj.rating[0].itemId === 2
  );
  let combinedServiceTypeTitle = "-";
  if (serviceObj && serviceObj.serviceType && serviceObj.serviceType.length) {
    combinedServiceTypeTitle = serviceObj.serviceType
      .map((sTypeObj) => sTypeObj.title)
      .join(" & ");
  }

  return {
    ...serviceObj,
    hasRatingGiven,
    isRatingGood,
    combinedServiceTypeTitle,
    ...(serviceObj && serviceObj.status && serviceObj.status.itemId
      ? {
          statusKey: serviceObj.status.itemId,
        }
      : {}),
    ...(serviceObj && serviceObj.rating && serviceObj.rating.length
      ? {
          ratingKey: serviceObj.rating[0].itemId,
        }
      : {}),
    ...(serviceObj && serviceObj.serviceFlow && serviceObj.serviceFlow.itemId
      ? {
          serviceFlowKey: serviceObj.serviceFlow.itemId,
        }
      : {}),
  };
};

export const manipulateCompletedStatus = (sObj, serviceStatus = []) => {
  /* Manipulating completed service to pending rate service as 1+4 */
  let serviceObj = { ...sObj };

  const completedStatusObj = serviceStatus.find((obj) => obj.itemId === 4);

  if (serviceObj && serviceObj.statusKey === 4) {
    if (serviceObj.hasRatingGiven) {
      delete serviceObj.modifiedStatusTitleKey;
      serviceObj = {
        ...serviceObj,
        statusKey: 7,
      };
    } else {
      serviceObj = {
        ...serviceObj,
        statusKey: 1,
        modifiedStatusTitleKey: 14,
        status: completedStatusObj,
      };
    }
  }

  return serviceObj;
};

export const modifyCompletedStatus = (sObj) => {
  /* Adding isCompletedStatusModified by modifiedStatusTitleKey 1+4 */
  const serviceObj = { ...sObj };

  const isCompletedStatusModified = !!(
    serviceObj &&
    serviceObj.modifiedStatusTitleKey &&
    serviceObj.modifiedStatusTitleKey === 14
  );
  return {
    ...serviceObj,
    isCompletedStatusModified,
  };
};
