import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useCommonContextDeps } from "services/contexts/CommonContext";

const styles = {
  backgroundColor: "rgba(255,255,255,0.5)",
  color: "#000",
  zIndex: 1400,
};

const BackdropLoader = () => {
  const { appState } = useCommonContextDeps();
  const { isLoading } = appState;
  // console.log("BackdropLoader", isLoading);
  return (
    <Backdrop style={styles} open={isLoading}>
      {isLoading ? <CircularProgress color="inherit" /> : null}
    </Backdrop>
  );
};

export default BackdropLoader;
