import {
  getDateNMonthBefore,
  getIsDateBeforePresentDate,
} from "helpers/formatDate";
import { transformMessageThread } from "./message";
import {
  manipulateCompletedStatus,
  modifyCompletedStatus,
  modifyServicesOfBuild,
} from "./service";

const sortByRecommendedDate = (a, b) => {
  const nullCheckVal =
    Number(a.recommendedDate === null) - Number(b.recommendedDate === null);
  return (
    nullCheckVal ||
    +(
      new Date(a.recommendedDate).getTime() >
      new Date(b.recommendedDate).getTime()
    ) ||
    -(
      new Date(a.recommendedDate).getTime() <
      new Date(b.recommendedDate).getTime()
    )
  );
};

const sortByServiceDate = (a, b) => {
  const nullCheckVal =
    Number(a.serviceDate === null) - Number(b.serviceDate === null);
  return (
    nullCheckVal ||
    +(new Date(a.serviceDate).getTime() > new Date(b.serviceDate).getTime()) ||
    -(new Date(a.serviceDate).getTime() < new Date(b.serviceDate).getTime())
  );
};

const sortServiceByStatusKey = (a, b) => {
  const nullCheckVal =
    Number(a.statusKey === null) - Number(b.statusKey === null);
  return (
    nullCheckVal || +(a.statusKey > b.statusKey) || -(a.statusKey < b.statusKey)
  );
};

const sortMessageThreadByStatusKey = (a, b) => {
  const nullCheckVal =
    Number(a.statusKey === null) - Number(b.statusKey === null);
  return (
    nullCheckVal || +(a.statusKey > b.statusKey) || -(a.statusKey < b.statusKey)
  );
};

export const sortByCustomRecommendedDate = (a, b) => {
  const dateA = a.recommended_date_by_pending_status;
  const dateB = b.recommended_date_by_pending_status;

  const nullCheckVal = Number(dateA === null) - Number(dateB === null);

  return (
    nullCheckVal ||
    +(new Date(dateA).getTime() > new Date(dateB).getTime()) ||
    -(new Date(dateA).getTime() < new Date(dateB).getTime())
  );
};

const getRecommendedDateByPendingStatus = (sortedServicesOfBuild) => {
  const serviceWithPendingStatus = sortedServicesOfBuild.find(
    ({ statusKey = null }) => !!(statusKey && statusKey === 1)
  );

  const recommendedDateOfServiceWithPendingStatus =
    serviceWithPendingStatus?.recommendedDate ?? null;

  return recommendedDateOfServiceWithPendingStatus;
};

export const modifyBuilds = (bObj, { serviceStatus }, canViewAndReply) => {
  const servicesOfBuild =
    bObj && bObj.services && bObj.services.length ? bObj.services : [];
  const messagesOfBuild =
    bObj && bObj.messages && bObj.messages.length ? bObj.messages : [];

  return {
    ...bObj,
    services: servicesOfBuild
      .map(modifyServicesOfBuild)
      .map((sObj) => manipulateCompletedStatus(sObj, serviceStatus))
      .map(modifyCompletedStatus),
    messages: canViewAndReply
      ? messagesOfBuild.map(transformMessageThread)
      : messagesOfBuild,
  };
};

const buildIdsList = {};
export const integrateBuildProps = (bObj) => {
  if (bObj && !buildIdsList[bObj.itemId]) {
    buildIdsList[bObj.itemId] = {};
    buildIdsList[bObj.itemId]["sort_status"] = null;
    buildIdsList[bObj.itemId]["sort_recommended_date"] = null;
    buildIdsList[bObj.itemId]["sort_service_date"] = null;
    buildIdsList[bObj.itemId]["unread_msg_thread_total_count"] = 0;
    buildIdsList[bObj.itemId]["recommended_date_by_pending_status"] = null;
  }

  if (bObj && bObj.services && bObj.services.length) {
    const sortedServices = bObj.services.sort(sortServiceByStatusKey);
    buildIdsList[bObj.itemId]["sort_status"] = sortedServices[0].statusKey;
    buildIdsList[bObj.itemId]["sort_recommended_date"] =
      bObj.services.sort(sortByRecommendedDate)[0].recommendedDate || null;
    buildIdsList[bObj.itemId]["sort_service_date"] =
      bObj.services.sort(sortByServiceDate)[0].serviceDate || null;
    buildIdsList[bObj.itemId]["recommended_date_by_pending_status"] =
      getRecommendedDateByPendingStatus(sortedServices);
  }

  if (bObj && bObj.messages && bObj.messages.length) {
    const sortedMessages = bObj.messages.sort(sortMessageThreadByStatusKey);
    buildIdsList[bObj.itemId]["sort_status"] =
      sortedMessages[0].statusKey === 0
        ? sortedMessages[0].statusKey
        : buildIdsList[bObj.itemId]["sort_status"];
    buildIdsList[bObj.itemId]["unread_msg_thread_total_count"] =
      sortedMessages.reduce(
        (sum, { unreadCommentsCount = 0 }) => sum + unreadCommentsCount,
        0
      );
  }

  return {
    ...bObj,
    sort_status: buildIdsList[bObj.itemId].sort_status,
    sort_recommended_date: buildIdsList[bObj.itemId].sort_recommended_date,
    sort_service_date: buildIdsList[bObj.itemId].sort_service_date,
    unread_msg_thread_total_count:
      buildIdsList[bObj.itemId].unread_msg_thread_total_count,
    recommended_date_by_pending_status:
      buildIdsList[bObj.itemId].recommended_date_by_pending_status,
  };
};

export const setIsNMonthBeforeDate = (bObj) => {
  let isNMonthBeforeRecommendedDate = false;
  const targetMonths = 1;

  const dateOneMonthBefore = bObj?.recommended_date_by_pending_status
    ? getDateNMonthBefore(bObj.recommended_date_by_pending_status, targetMonths)
    : null;

  isNMonthBeforeRecommendedDate =
    !!dateOneMonthBefore && getIsDateBeforePresentDate(dateOneMonthBefore);

  return {
    ...bObj,
    isNMonthBeforeRecommendedDate,
  };
};
