export const actionTypes = {
  updateView: "UPDATE_VIEW",
  makeApiCall: "MAKE_API_CALL",
  succesApiCall: "SUCCESS_API_CALL",
  errorApiCall: "ERROR_API_CALL",
  resetError: "RESET_ERROR",
};

function commonReducer(state, action) {
  // console.log("action", { state, action });
  switch (action.type) {
    case actionTypes.makeApiCall: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.succesApiCall: {
      return {
        ...state,
        isLoading: false,
        hasError: {},
        ...action.response,
      };
    }
    case actionTypes.errorApiCall: {
      return {
        ...state,
        isLoading: false,
        hasError: action.error,
      };
    }
    case actionTypes.resetError: {
      return {
        ...state,
        hasError: {},
      };
    }
    default: {
      return state;
    }
  }
}

export default commonReducer;
