import { API_URL } from "constants/api";
import {
  getDateInText,
  getNorwayDateTime,
  hourMinute,
  mediumDate,
} from "helpers/formatDate";

function getMsgAcceptanceStatus(mObj) {
  const msgStatusVal = mObj && mObj.acceptance ? mObj.acceptance : null;

  const isNotRelevant = msgStatusVal === 3;
  const isPendingAcceptance = msgStatusVal === 4;
  const isAccepted = msgStatusVal === 1;
  const isDeclined = msgStatusVal === 2;

  const msgStatusObj = {
    msgStatusVal,
    isNotRelevant,
    isPendingAcceptance,
    isAccepted,
    isDeclined,
  };
  // console.log(msgStatusObj);
  return msgStatusObj;
}

function getUserInitials(userName = "") {
  const splittedName =
    userName && userName.split(" ").length
      ? userName.split(" ").slice(0, 2)
      : ["-"];

  const userInitials = splittedName
    .map((str) => (str ? str[0].toUpperCase() : ""))
    .join("");

  return userInitials;
}

export const transformMessageThread = (mObj) => {
  const commentList =
    mObj && mObj.comments && mObj.comments.length ? mObj.comments : [];

  const {
    isNotRelevant,
    isPendingAcceptance,
    isAccepted,
    isDeclined,
    msgStatusVal,
  } = getMsgAcceptanceStatus(mObj);

  const isOpenEnded = isNotRelevant || isAccepted || isDeclined;
  const isCloseEnded = isPendingAcceptance;
  const mThreadItemId = mObj && mObj.mThreadItemId ? mObj.mThreadItemId : null;
  let unreadCommentsCount = commentList.length;
  const threadsOfTargetAdminComment = {};
  let messageStatusKey = null;
  const reversedCommentList = commentList.slice().reverse();
  const isFirstCommentByUserInReversedList = reversedCommentList.some(
    (obj, index) => index === 0 && !obj.is_admin
  );
  const areAllCommentsByAdmin = reversedCommentList.every(
    (obj) => obj.is_admin
  );
  if (!isFirstCommentByUserInReversedList) {
    reversedCommentList.forEach((obj, index) => {
      if (!obj.is_admin) {
        const prevComment =
          reversedCommentList && reversedCommentList[index - 1]
            ? reversedCommentList[index - 1]
            : null;
        const isAdminCommentAlreadyAvailable = !!(
          threadsOfTargetAdminComment &&
          threadsOfTargetAdminComment[mThreadItemId]
        );
        if (
          prevComment &&
          prevComment.is_admin &&
          !isAdminCommentAlreadyAvailable
        ) {
          threadsOfTargetAdminComment[mThreadItemId] = {
            ...prevComment,
            mThreadItemId,
          };
        }
      }
    });
  }
  if (
    areAllCommentsByAdmin &&
    reversedCommentList &&
    reversedCommentList.length
  ) {
    const lastCommentByAdmin =
      reversedCommentList[reversedCommentList.length - 1];
    threadsOfTargetAdminComment[mThreadItemId] = {
      ...lastCommentByAdmin,
      mThreadItemId,
    };
  }

  const targetAdminCommentObj = threadsOfTargetAdminComment[mThreadItemId];

  if (isFirstCommentByUserInReversedList) {
    unreadCommentsCount = null;
  }
  if (unreadCommentsCount) messageStatusKey = 0;

  const modifyComments = (cObj, commentIndex) => {
    const commentInHTML =
      cObj && cObj.text ? cObj.text.replace(/<[^>]*>?/gm, "") : "-";
    const isAdmin = !!(cObj && cObj.is_admin);
    const created_by = cObj.created_by;
    const userInitials = getUserInitials(created_by);
    const imagesWithUrl =
      cObj && cObj.images && cObj.images.length
        ? cObj.images.map((imgPath) => `${API_URL}${imgPath}`)
        : [];

    const commentReceivedTimeStamp =
      cObj && cObj.created_on
        ? `${getDateInText(cObj.created_on, mediumDate)} ${getNorwayDateTime(
            cObj.created_on,
            hourMinute
          )}`
        : "-";
    const isLastComment = commentList.length - 1 === commentIndex;
    const isFirstComment = commentIndex === 0;

    const targetAdminCommentObjKeys =
      targetAdminCommentObj && Object.keys(targetAdminCommentObj).length
        ? Object.keys(targetAdminCommentObj)
        : null;

    const isRead = !(
      targetAdminCommentObjKeys &&
      targetAdminCommentObj.comment_id === cObj.comment_id
    );

    return {
      ...cObj,
      unreadCommentsCount,
      commentInHTML,
      commentReceivedTimeStamp,
      isOpenEnded,
      isCloseEnded,
      isFirstComment,
      isLastComment,
      mThreadItemId,
      isNotRelevant,
      isPendingAcceptance,
      isAccepted,
      isDeclined,
      isAdmin,
      userInitials,
      created_by,
      isRead,
      acceptance: msgStatusVal,
      imagesWithUrl,
    };
  };

  return {
    ...mObj,
    comments: [...commentList].map(modifyComments),
    unreadCommentsCount,
    statusKey: messageStatusKey,
  };
};
