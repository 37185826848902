import styled from "styled-components";
import styledSystem from "helpers/styledSystem";
import { closeIconSrcPath } from "constants/asset";

export const BaseCloseButton = styledSystem(styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors["red"]};

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    opacity: 1;
    background-image: url(${closeIconSrcPath});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
  }
`);
