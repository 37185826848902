import { StrictMode } from "react";
import ReactDom from "react-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";

const swConfig = {
  onUpdate: async (registration) => {
    alert("Ny versjon tilgjengelig! Klar til å oppdatere?");
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    if (registration && registration.waiting) {
      await registration.unregister();
      // Makes Workbox call skipWaiting().
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      window.location.reload();
    }
  },
};

ReactDom.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.register(swConfig);
