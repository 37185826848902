import React from "react";
import styled from "styled-components";
import MuiSkeleton from "@mui/material/Skeleton";

import styledSystem from "helpers/styledSystem";

import { BaseText, BaseWrap } from "components/base";
import { useCommonContextDeps } from "services/contexts/CommonContext";
const HWrap = styledSystem(styled(BaseWrap)``);
const HUserName = styledSystem(styled(BaseText)`
  max-width: 350px;
`);

const HCompanyName = styledSystem(styled(BaseText)`
  text-transform: uppercase;
  max-width: 350px;
`);

export default function UserInfo({ norwegianFieldMap }) {
  const { appState } = useCommonContextDeps();
  const { loggedInUserName, client } = appState;
  const clientCompanyName =
    client && client.length && client[0].companyName ? (
      client[0].companyName
    ) : (
      <MuiSkeleton width={120} />
    );

  const signedInText =
    norwegianFieldMap &&
    norwegianFieldMap["signed-in-as"] &&
    loggedInUserName ? (
      `${norwegianFieldMap["signed-in-as"]} ${loggedInUserName}`
    ) : (
      <MuiSkeleton width="100%" />
    );

  return (
    <HWrap
      bg={"client-info-bg-grey"}
      px={"40px"}
      pt={"24px"}
      pb={"20px"}
      minWidth="350px"
    >
      <HCompanyName
        fontFamily="rubik"
        fontSize={16}
        fontWeight={500}
        color="black"
        lineHeight="18.96px"
      >
        {clientCompanyName}
      </HCompanyName>
      <HUserName
        fontFamily="proxima-nova"
        fontSize={15}
        fontWeight={400}
        color="text-grey"
      >
        {signedInText}
      </HUserName>
    </HWrap>
  );
}
