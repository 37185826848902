export const getValidatedFilters = (filters) => {
  const validatedFilterObj = {};
  filters &&
    Object.keys(filters).length &&
    Object.keys(filters).forEach((filterKey) => {
      if (!validatedFilterObj[filterKey]) {
        validatedFilterObj[filterKey] = [];
      }
      validatedFilterObj[filterKey] = filters[filterKey];
    });
  return validatedFilterObj;
};
