import React, {
  useReducer,
  createContext,
  useContext,
  useRef,
  useMemo,
} from "react";
import { useWindowSize as useWindowSizeD } from "@react-hook/window-size";

import commonReducer from "services/reducers/commonReducer";
import { privateRouteList } from "constants/route";
import {
  permissionsByRole,
  projectAccessPermissionForDriverRole,
} from "constants/permissions";
import { getValidatedFilters } from "helpers/getValidatedFilters";
import {
  integrateBuildProps,
  modifyBuilds,
  setIsNMonthBeforeDate,
} from "domain/build";
import { integrateProjectProps, modifyProjects } from "domain/project";
import { getUserRights } from "domain/user";
import { getNormalizedTranslationData } from "helpers/getNormalizedTranslationData";

const initialAppState = {
  isLoading: false,
  hasError: {},
};

export const CommonContext = createContext({});

export function CommonContextProvider({ children }) {
  const [appState, dispatch] = useReducer(commonReducer, initialAppState);
  const {
    projects = [],
    builds = [],
    filters,
    userRights,
    user,
    translations = [],
    client,
  } = appState;
  const [widthD] = useWindowSizeD();
  const isMediumAbove = widthD >= 960;
  const role = user?.role ?? null;
  const userItemId = user?.itemId ?? null;
  const loggedInUserName = user?.name ?? null;
  const roleTitleKey = role?.title ?? null;
  const isOwner = roleTitleKey !== "Driver";
  const isDriver = roleTitleKey === "Driver";
  const clientCompanyName = client?.[0]?.companyName ?? null;
  const clientItemId = client?.[0]?.itemId ?? null;

  const { canViewAndReply, canReqServiceAndAppointment, canRateService } =
    getUserRights(isOwner, userRights);

  let privateRoutesByPerms = privateRouteList;
  if (roleTitleKey) {
    privateRoutesByPerms = privateRouteList.filter((obj) => {
      if (isDriver && projects && projects.length) {
        return projectAccessPermissionForDriverRole.includes(obj.pageKey);
      } else {
        return permissionsByRole[roleTitleKey].includes(obj.pageKey);
      }
    });
  }

  const updatedBuildsData = builds
    .map((bObj) => modifyBuilds(bObj, filters, canViewAndReply))
    .map(integrateBuildProps)
    .map(setIsNMonthBeforeDate);

  const updatedProjectsData = projects
    .map((pObj) => modifyProjects(pObj, canViewAndReply))
    .map(integrateProjectProps);

  const validatedFilterObj = getValidatedFilters(filters);

  const normalizedTranslationData = getNormalizedTranslationData(translations);

  const projectDetailDrawerRef = useRef(null);
  const buildDetailDrawerRef = useRef(null);
  const newsDetailDrawerRef = useRef(null);

  const addNewConfirmDrawerRef = useRef(null);
  const removeConfirmDrawerRef = useRef(null);

  const addNewDriverDrawerRef = useRef(null);
  const editDriverDrawerRef = useRef(null);

  const requestServiceDrawerRef = useRef(null);
  const requestAppointmentDrawerRef = useRef(null);
  const serviceDetailDrawerRef = useRef(null);
  const serviceRateDrawerRef = useRef(null);

  const currBuildItemIdRef = useRef(null);
  const currProjectItemIdRef = useRef(null);

  const messageDrawerRef = useRef(null);
  const addNewBuildDrawerRef = useRef(null);

  const updatedAppState = useMemo(
    () => ({
      ...appState,
      builds: updatedBuildsData,
      projects: updatedProjectsData,
      filters: validatedFilterObj,
      isMediumAbove,
      userItemId,
      loggedInUserName,
      canReqServiceAndAppointment,
      canViewAndReply,
      canRateService,
      norwegianFieldMap: normalizedTranslationData,
      isOwner,
      isDriver,
      clientCompanyName,
      clientItemId,
    }),
    [
      appState,
      updatedBuildsData,
      updatedProjectsData,
      validatedFilterObj,
      isMediumAbove,
      userItemId,
      loggedInUserName,
      canReqServiceAndAppointment,
      canViewAndReply,
      canRateService,
      normalizedTranslationData,
      isOwner,
      isDriver,
      clientCompanyName,
      clientItemId,
    ]
  );

  // console.log("appState", {
  //   appState: updatedAppState,
  //   privateRoutesByPerms,
  // });

  return (
    <CommonContext.Provider
      value={{
        appState: updatedAppState,
        privateRoutesByPerms,
        projectDetailDrawerRef,
        buildDetailDrawerRef,
        newsDetailDrawerRef,
        addNewConfirmDrawerRef,
        removeConfirmDrawerRef,
        addNewDriverDrawerRef,
        editDriverDrawerRef,
        requestServiceDrawerRef,
        requestAppointmentDrawerRef,
        serviceDetailDrawerRef,
        serviceRateDrawerRef,
        currBuildItemIdRef,
        currProjectItemIdRef,
        messageDrawerRef,
        addNewBuildDrawerRef,
        dispatch,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
}

export function useCommonContextDeps() {
  const context = useContext(CommonContext);
  if (!context) {
    throw new Error(`Components cannot be rendered outside the App component`);
  }
  return context;
}
