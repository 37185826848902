import React from "react";
import { useParams, Redirect, useLocation } from "react-router-dom";

import { routes } from "constants/route";
import PrivateRoute from "routes/PrivateRoute";
import { useCommonContextDeps } from "services/contexts/CommonContext";

function getMatchedRoute(privateRoutesByPerms, slug) {
  return privateRoutesByPerms.find(({ routeId, isShown }) => {
    return slug === routeId && isShown;
  });
}

function PrivateView() {
  const { privateRoutesByPerms } = useCommonContextDeps();
  const { pageId } = useParams();
  const location = useLocation();
  // console.log("PrivateView");
  const matchedRoute = getMatchedRoute(privateRoutesByPerms, `/${pageId}`);
  if (matchedRoute && matchedRoute.pageKey) {
    const pageKey = matchedRoute.pageKey;
    return <PrivateRoute pageKey={pageKey} />;
  }
  return (
    <Redirect
      to={{
        pathname: routes.URL_INVALID,
        state: { from: location },
      }}
    />
  );
}

export default PrivateView;
