import { useEffect } from "react";
import translationAPI from "services/api/translation";
import { useCommonContextDeps } from "services/contexts/CommonContext";

const useTranslation = () => {
  const { dispatch } = useCommonContextDeps();

  useEffect(() => {
    async function loadTranslations() {
      const translationApiData = await translationAPI.getAll();
      if (translationApiData && translationApiData.status) {
        dispatch({
          type: "SUCCESS_API_CALL",
          response: { translations: translationApiData.res },
        });
      } else {
        dispatch({
          type: "ERROR_API_CALL",
          translationApiData,
        });
      }
    }
    loadTranslations();
  }, [dispatch]);
};

export default useTranslation;
