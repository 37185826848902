import styled from "styled-components";
import styledSystem from "helpers/styledSystem";

export const BaseImage = styledSystem(styled.div`
  position: relative;
  background: url(${({ src = "" }) => src}) center center no-repeat;
  background-size: cover;
  width: 100%;
  padding-bottom: ${({ w, h }) => {
    return (h / w) * 100;
  }}%;
  cursor: pointer;
`);
