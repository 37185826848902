import { Route } from "react-router-dom";
import styled from "styled-components";

import styledSystem from "helpers/styledSystem";
import { BaseWrap } from "components/base";

const HWrap = styledSystem(styled(BaseWrap)``);

function PublicRoute({ component: Component, ...rest }) {
  // console.log("PublicRoute - rest", { Component, rest });
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <HWrap
            bg="bg-grey"
            display="grid"
            gridTemplateColumns="1fr"
            gridTemplateRows="auto"
            justifyContent="center"
            height="100vh"
          >
            {Component}
          </HWrap>
        );
      }}
    />
  );
}

export default PublicRoute;
