export const LOCAL_HOST = window.location.origin;

export const routes = {
  URL_LOGIN: "/login",
  URL_PASSWORD_UPDATE: "/password-update/",
  URL_PASSWORD_FORGOT: "/password-forgot",
  URL_PROJECTS: "/projects",
  URL_BUILDS: "/builds",
  URL_DRIVERS: "/drivers",
  URL_SETTINGS: "/settings",
  URL_NEWS: "/news",
  URL_INVALID: "/page/invalid",
  URL_VALIDATE_LINK: "/validate-link",
};

export const privateRouteList = [
  {
    pageKey: "projects",
    label: "Projects",
    routeId: routes.URL_PROJECTS,
    isShown: true,
    isBadgeShown: true,
  },
  {
    pageKey: "builds",
    label: "Builds",
    routeId: routes.URL_BUILDS,
    isShown: true,
    isBadgeShown: true,
  },
  {
    pageKey: "drivers",
    label: "Drivers",
    routeId: routes.URL_DRIVERS,
    isShown: true,
    isBadgeShown: false,
  },
  {
    pageKey: "news",
    label: "News",
    routeId: routes.URL_NEWS,
    isShown: true,
    isBadgeShown: false,
  },
  {
    pageKey: "settings",
    label: "Settings",
    routeId: routes.URL_SETTINGS,
    isShown: true,
    isBadgeShown: false,
  },
];

export const publicRouteList = [
  {
    pageKey: "login",
    routeId: routes.URL_LOGIN,
    isShown: true,
  },
  {
    pageKey: "password-forgot",
    routeId: routes.URL_PASSWORD_FORGOT,
    isShown: true,
  },
  {
    pageKey: "password-update",
    routeId: routes.URL_PASSWORD_UPDATE,
    isShown: true,
  },
  {
    pageKey: "validate-link",
    routeId: routes.URL_VALIDATE_LINK,
    isShown: true,
  },
];
