import { useEffect, useState } from "react";

export const targetStorage = localStorage;

const useStorage = (key, defaultVal) => {
  const [value, setValue] = useState(() => {
    const tempValue = targetStorage.getItem(key);
    return tempValue !== null ? tempValue : defaultVal;
  });

  useEffect(() => {
    if (value) {
      targetStorage.setItem(key, value);
    }
  }, [key, value]);

  return [value, setValue];
};

export default useStorage;
