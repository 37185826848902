import styled from "styled-components";
import styledSystem from "helpers/styledSystem";

export const BaseIcon = styledSystem(styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 22px;
`);
