import styled from "styled-components";
import styledSystem from "helpers/styledSystem";
import { Button } from "antd";

export const BaseButton = styledSystem(styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  color: ${({ theme, variant = "" }) =>
    variant === "primary" ? theme.colors["text-white"] : theme.colors["black"]};
  background-color: ${({ theme, variant = "" }) =>
    variant === "primary"
      ? theme.colors["hengsrod-green"]
      : theme.colors["button-grey"]};
  border: none;
  font-family: "proxima-nova";
  font-size: 14px;
  text-transform: uppercase;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "cursor")};
`);
