import React from "react";
import styled from "styled-components";
import styledSystem from "helpers/styledSystem";

import { BaseText, BaseWrap } from "components/base";
import { CloseIcon } from "constants/icon";
import { useCommonContextDeps } from "services/contexts/CommonContext";

const HCloseMenu = styledSystem(styled(BaseWrap)``);
const HText = styledSystem(styled(BaseText)`
  text-transform: uppercase;
`);
const HCloseIcon = styledSystem(styled(BaseWrap)``);

const iconMap = {
  close: CloseIcon,
};

export default function HRectCloseButton({
  handleDrawerCloseButtonClick = () => null,
}) {
  const { appState } = useCommonContextDeps();
  const { norwegianFieldMap } = appState;

  return (
    <HCloseMenu
      display={{ _: "flex", md: "none", lg: "none", xl: "none" }}
      justifyContent={{ _: "center" }}
      alignItems={{ _: "center" }}
      bg={{ _: "menu-bg-grey" }}
      height={{ _: 60 }}
      width={{ _: "100%" }}
      onClick={handleDrawerCloseButtonClick}
    >
      <HCloseIcon>{iconMap["close"]}</HCloseIcon>
      <HText
        ml={2}
        mt={1}
        fontWeight={{ _: 500 }}
        fontFamily={{ _: "rubik" }}
        color={"text-grey"}
      >
        {norwegianFieldMap["close"]}
      </HText>
    </HCloseMenu>
  );
}
