import { useState } from "react";
import styled from "styled-components";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import styledSystem from "helpers/styledSystem";
import { BaseText, BaseWrap } from "components/base";

import { CloseIcon, MenuMobileIcon } from "constants/icon";
import HSideMenuItems from "components/molecules/HSideMenuItems";
import { HengsrodLogoSmall } from "components/atoms/HengsrodLogoSmall";
import UserInfo from "components/atoms/UserInfo";
import { HNeedHelp } from "components/atoms/HNeedHelp";
import { HLogoWrapper } from "./HLogoWrapper";
import HRectCloseButton from "components/atoms/HRectCloseButton";
import useScrollListener from "services/hooks/useScrollListener";

const HWrap = styledSystem(styled(BaseWrap)``);
const HMenuIcon = styledSystem(styled(BaseWrap)``);

const HText = styledSystem(styled(BaseText)``);
const HMainMenu = styledSystem(styled(BaseWrap)``);
const HMenuSection = styledSystem(styled(BaseWrap)`
  &::-webkit-scrollbar {
    display: none;
  }
`);
const HTopMenuWrap = styledSystem(styled(BaseWrap)`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
  transition: transform 150ms ease-in-out;
  ${({ isMenuVisible }) => {
    return isMenuVisible
      ? ``
      : `
    transform: translateY(-100%)`;
  }}
`);

const iconMap = {
  menu: MenuMobileIcon,
  close: CloseIcon,
};

export default function HMobileTopMenu({ norwegianFieldMap }) {
  const [isInsetDrawerOpen, setInsetDrawerOpen] = useState(false);
  const { isMenuVisible } = useScrollListener();

  const handleOpenDrawer = () => {
    setInsetDrawerOpen(true);
  };

  const handleDrawerCloseButtonClick = () => {
    setInsetDrawerOpen(false);
  };

  const handleDrawerOpenButtonClick = () => {
    setInsetDrawerOpen(true);
  };

  return (
    <HTopMenuWrap isMenuVisible={isMenuVisible}>
      <HWrap
        as="span"
        display={{ _: "flex" }}
        justifyContent={{ _: "space-between" }}
        alignItems={{ _: "center" }}
        bg={{ _: "bg-grey" }}
        boxShadow={2}
      >
        <HMainMenu
          display={{ _: "flex" }}
          justifyContent={{ _: "center" }}
          alignItems={{ _: "center" }}
          bg={{ _: "menu-bg-grey" }}
          height={{ _: 60 }}
          width={{ _: 115 }}
          onClick={handleOpenDrawer}
        >
          <HMenuIcon>{iconMap["menu"]}</HMenuIcon>
          <HText
            ml={2}
            fontWeight={{ _: 500 }}
            fontFamily={{ _: "rubik" }}
            color={"text-grey"}
          >
            {norwegianFieldMap["menu"]}
          </HText>
        </HMainMenu>
        <HengsrodLogoSmall width={50} mr={15} />
      </HWrap>
      <SwipeableDrawer
        anchor={"left"}
        open={isInsetDrawerOpen}
        onOpen={handleDrawerOpenButtonClick}
        onClose={handleDrawerCloseButtonClick}
        disableSwipeToOpen={true}
        swipeAreaWidth={0}
      >
        <HWrap>
          <HRectCloseButton
            handleDrawerCloseButtonClick={handleDrawerCloseButtonClick}
          />
          <HWrap>
            <HMenuSection
              display="grid"
              gridTemplateColumns="100%"
              gridTemplateRows="auto 1fr auto auto auto"
              alignItems="flex-start"
              overflow="auto"
              height="calc(100vh - 60px)"
              bg="bg-grey"
            >
              <HSideMenuItems
                handleDrawerCloseButtonClick={handleDrawerCloseButtonClick}
              />
              <span></span>
              <UserInfo norwegianFieldMap={norwegianFieldMap} />
              <HNeedHelp norwegianFieldMap={norwegianFieldMap} />
              <HLogoWrapper
                handleDrawerCloseButtonClick={handleDrawerCloseButtonClick}
              />
            </HMenuSection>
          </HWrap>
        </HWrap>
      </SwipeableDrawer>
    </HTopMenuWrap>
  );
}
