import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { HengsrodLogoSmall } from "./HengsrodLogoSmall";

const styles = {
  backgroundColor: "rgba(255,255,255,0.5)",
  color: "#000",
  zIndex: 1400,
};

const SuspenseLoader = (
  <Backdrop style={styles} open={true}>
    <HengsrodLogoSmall width={150} />
  </Backdrop>
);

export default SuspenseLoader;
