/* APP API URLs */

//Production
export const API_URL = `https://api-portal.hengsrod.no`;
export const API_URL_BASE = `https://api-portal.hengsrod.no/api`;
export const API_URL_WS = `wss://api-portal.hengsrod.no`;

export const API_LOGIN = `/login`;
export const API_VERIFY_USER = `/verify-user`;
export const API_UPDATE_PASSWORD = `/update-password`;
export const API_RESET_PASSWORD = `/reset-password`;
export const API_CHECK_EXPIRY = `/check-expiry`;

export const API_METADATA = `/metadata`;
export const API_PROJECT = `/project`;
export const API_BUILD = `/build`;
export const API_DRIVER = `/driver`;
export const API_SERVICE_RATE = `/service/rate`;
export const API_SERVICE_REQUEST = `/service/request`;
export const API_SERVICE_APPOINTMENT = `/service/appointment`;
export const API_PROFILE_UPDATE = `/profile-update`;
export const API_NOTIFICATION_SETTINGS = `/notification-settings`;
export const API_TRANSLATIONS = `/translations`;
// export const API_PODIO_SYNC = `/podio/sync`;
// export const API_PODIO_SPACE_APPS = `/podio/:space_id/apps`;
