import { memo } from "react";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import { routes } from "constants/route";

import styledSystem from "helpers/styledSystem";
import useStorage from "services/hooks/useStorage";
import { hengsrodLogoWithName } from "constants/asset";

const HPicture = styledSystem(styled.picture`
  cursor: pointer;
`);
const HImg = styledSystem(styled.img`
  cursor: pointer;
`);

export const HengsrodLogo = memo((props) => {
  const history = useHistory();
  const [token] = useStorage("token");
  let directUrl = routes.URL_LOGIN;
  if (token) {
    directUrl = routes.URL_BUILDS || routes.URL_PROJECTS;
  }
  return (
    <HPicture>
      <HImg
        {...props}
        src={hengsrodLogoWithName}
        title="Hengsrod Logo"
        alt="Hengsrod Logo"
        onClick={() => history.push(directUrl)}
      />
    </HPicture>
  );
});
