import React from "react";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import theme from "constants/theme";
import "./App.scss";

import { CommonContextProvider } from "services/contexts/CommonContext";
import Routes from "routes";
import { ErrorBoundaryWrapper } from "components/shared/ErrorBoundary";

const GlobalStyle = createGlobalStyle`
  :root {
    --hengsrod-green: #81BE48;
  }
`;

const App = () => {
  return (
    <ErrorBoundaryWrapper>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CommonContextProvider>
          <Routes />
        </CommonContextProvider>
      </ThemeProvider>
    </ErrorBoundaryWrapper>
  );
};

export default App;
