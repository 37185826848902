import React, { lazy, useEffect } from "react";
import styled from "styled-components";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";

import metaDataAPI from "services/api/metadata";
import { useCommonContextDeps } from "services/contexts/CommonContext";
import { SocketContextProvider } from "services/contexts/SocketContext";

import { BaseWrap } from "components/base";
import HSideMenu from "components/molecules/HSideMenu";
import HMobileTopMenu from "components/molecules/HMobileTopMenu";

import styledSystem from "helpers/styledSystem";

import { routes } from "constants/route";
import useStorage, { targetStorage } from "services/hooks/useStorage";
import useTranslation from "services/hooks/useTranslation";

const Projects = lazy(() => import("views/private/Projects"));
const Builds = lazy(() => import("views/private/Builds"));
const Drivers = lazy(() => import("views/private/Drivers"));
const News = lazy(() => import("views/private/News"));
const Settings = lazy(() => import("views/private/Settings"));

const HWrap = styledSystem(styled(BaseWrap)``);

const privateRouteMap = {
  projects: Projects,
  builds: Builds,
  drivers: Drivers,
  settings: Settings,
  news: News,
};

export function PrivateRoute({ pageKey }) {
  const { dispatch, appState } = useCommonContextDeps();
  const { isMediumAbove, norwegianFieldMap } = appState;
  const history = useHistory();
  const location = useLocation();
  const [token] = useStorage("token");
  const [email] = useStorage("email");
  const isLoggedIn = !!token;
  useTranslation();

  useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        dispatch({ type: "MAKE_API_CALL" });
        const metaDataResponse = await metaDataAPI.getOne(email);
        if (metaDataResponse && metaDataResponse.status) {
          dispatch({
            type: "SUCCESS_API_CALL",
            response: metaDataResponse.res,
          });
        } else {
          dispatch({
            type: "ERROR_API_CALL",
            error: metaDataResponse,
          });
          history.push(routes.URL_LOGIN);
          targetStorage.clear();
        }
      })();
    }
  }, [isLoggedIn, token, email, history, dispatch]);

  // console.log("PrivateRoute", pageKey);

  const userAuthProps = {
    token,
    isLoggedIn,
  };

  const PrivateComponent = privateRouteMap[pageKey];
  if (isLoggedIn) {
    return (
      <SocketContextProvider {...userAuthProps}>
        <Route
          exact
          render={() => {
            return isMediumAbove ? (
              <HWrap display="flex" height="100vh" bg="white">
                <HSideMenu norwegianFieldMap={norwegianFieldMap} />
                <PrivateComponent />
              </HWrap>
            ) : (
              <HWrap display="flex" flexDirection="column" bg="white">
                <HMobileTopMenu norwegianFieldMap={norwegianFieldMap} />
                <PrivateComponent />
              </HWrap>
            );
          }}
        />
      </SocketContextProvider>
    );
  }
  const locPathWithSearchParams = `${location.pathname}${location.search}`;
  return (
    <Redirect
      to={{
        pathname: routes.URL_LOGIN,
        state: {
          from: {
            ...location,
            pathname: locPathWithSearchParams,
          },
        },
      }}
    />
  );
}

export default PrivateRoute;
