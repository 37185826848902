import styled from "styled-components";
import styledSystem from "helpers/styledSystem";

import { API_URL } from "constants/api";
import { hengsrodLogo3x } from "constants/asset";

export const BaseServerImage = styledSystem(styled.div`
  position: relative;
  background: url(${({ src = "" }) =>
      src ? `${API_URL}${src}` : hengsrodLogo3x})
    center center no-repeat;
  background-size: cover;
  width: 100%;
  padding-bottom: ${({ w, h }) => (h / w) * 100}%;
  cursor: pointer;
`);
