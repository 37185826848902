import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import styled from "styled-components";
import styledSystem from "helpers/styledSystem";

import { BaseText, BaseWrap } from "components/base";

const HText = styledSystem(styled(BaseText)``);
const HWrap = styledSystem(styled(BaseWrap)`
  margin: 15px;
`);

function ErrorFallback({ ...errorObj }) {
  if (errorObj && !Object.keys(errorObj).length) return null;
  return (
    <HWrap
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      color={"error-red-text"}
    >
      <HText fontWeight={600} fontSize={20} textAlign="left" mb={1}>
        Something went wrong!
      </HText>
      <HText mb={3}>
        {errorObj && errorObj.error && errorObj.error.message
          ? errorObj.error.message
          : ""}
      </HText>
      <HText>
        {errorObj && errorObj.info && errorObj.info.componentStack
          ? errorObj.info.componentStack
          : ""}
      </HText>
    </HWrap>
  );
}

export const ErrorBoundaryWrapper = ({ children }) => {
  const [errorObj, setErrorObj] = useState({});

  const myErrorHandler = (error, info) => {
    setErrorObj({ ...errorObj, error, info });
  };

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorFallback {...errorObj} />}
      onError={myErrorHandler}
    >
      {children}
    </ErrorBoundary>
  );
};
