import ApiCore from "./utils/core";
import { API_METADATA } from "constants/api";

const resourcePath = API_METADATA;

const options = {
  getOne: true,
  resourcePath: resourcePath,
};
// metaDataAPI.customMethod = () => {};

const metaDataAPI = new ApiCore(options);

// console.log("metaDataAPI", metaDataAPI);

export default metaDataAPI;
