import styled, { css } from "styled-components";
import { ReactSVG } from "react-svg";
import styledSystem from "helpers/styledSystem";
import {
  attachementIconSrcPath,
  calendarIconSrcPath,
  chatMessageIconSrcPath,
  closeIconSrcPath,
  driversIconSrcPath,
  errorInfoIconSrcPath,
  infoIconSrcPath,
  menuMobileIconSrcPath,
  moduleIconSrcPath,
  newsIconSrcPath,
  pendingActionIconSrcPath,
  phoneIconSrcPath,
  plusIconSrcPath,
  rateGoodIconSrcPath,
  settingIconSrcPath,
  signOutIconSrcPath,
  tourGuideIconSrcPath,
} from "./asset";

const StyledSVGIcon = styledSystem(styled(ReactSVG)`
  svg {
    color: black;
    ${({ size }) =>
      size &&
      css`
        width: ${size};
        height: ${size};
      `}
    ${({ transform }) =>
      transform &&
      css`
        transform: ${transform};
      `}
    path {
      ${({ color, theme }) => {
        return (
          color &&
          css`
            fill: ${theme.colors[color]};
          `
        );
      }}
    }
  }
`);

const ErrorInfoIcon = (
  <StyledSVGIcon src={errorInfoIconSrcPath} title="Error Info" />
);

const ModuleIcon = ({ color }) => (
  <StyledSVGIcon src={moduleIconSrcPath} title="Module" color={color} />
);

const DriversIcon = ({ color }) => (
  <StyledSVGIcon src={driversIconSrcPath} color={color} title="Drivers" />
);

const NewsIcon = ({ color }) => (
  <StyledSVGIcon src={newsIconSrcPath} color={color} title="News" />
);

const SettingsIcon = ({ color }) => (
  <StyledSVGIcon src={settingIconSrcPath} color={color} title="Settings" />
);

const SignOutIcon = ({ color }) => (
  <StyledSVGIcon src={signOutIconSrcPath} color={color} title="SignOut" />
);

const PhoneIcon = <StyledSVGIcon src={phoneIconSrcPath} title="Phone" />;

const MenuMobileIcon = (
  <StyledSVGIcon src={menuMobileIconSrcPath} title="Menu" />
);

const CloseIcon = (
  <StyledSVGIcon src={closeIconSrcPath} title="Close" width={10} />
);

const PlusIcon = ({ ...rest }) => (
  <StyledSVGIcon src={plusIconSrcPath} title="Plus" {...rest} />
);

const DocPinIcon = ({ ...rest }) => (
  <StyledSVGIcon src={attachementIconSrcPath} {...rest} title="DocPin" />
);

const RateGoodIcon = ({ size }) => (
  <StyledSVGIcon src={rateGoodIconSrcPath} size={size} title="RateThumb" />
);

const RateBadIcon = ({ size }) => (
  <StyledSVGIcon
    src={rateGoodIconSrcPath}
    size={size}
    title="RateThumb"
    transform={"rotate(180deg)"}
  />
);

const PendingActionIcon = ({ ...rest }) => (
  <StyledSVGIcon
    src={pendingActionIconSrcPath}
    title="PendingAction"
    {...rest}
  />
);

const UnreadMessageIcon = ({ ...rest }) => (
  <StyledSVGIcon src={chatMessageIconSrcPath} title="UnreadMessage" {...rest} />
);

const CalendarIcon = (
  <StyledSVGIcon src={calendarIconSrcPath} title="Calendar" />
);

const InfoIcon = ({ color, size }) => (
  <StyledSVGIcon src={infoIconSrcPath} color={color} size={size} title="Info" />
);

const TourGuideIcon = ({ color, size }) => (
  <StyledSVGIcon
    src={tourGuideIconSrcPath}
    title="Tour"
    color={color}
    size={size}
  />
);

export {
  ModuleIcon,
  DriversIcon,
  NewsIcon,
  SettingsIcon,
  PhoneIcon,
  SignOutIcon,
  ErrorInfoIcon,
  MenuMobileIcon,
  CloseIcon,
  PlusIcon,
  DocPinIcon,
  RateGoodIcon,
  RateBadIcon,
  PendingActionIcon,
  UnreadMessageIcon,
  CalendarIcon,
  InfoIcon,
  TourGuideIcon,
};
